.profile_settings {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 30px 0;
}

.profile_settings h4 {
  background-color: #971b59;
  color: #fff;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 0;
}

.profile_info h6{
  background-color: #f6f6f6;
  padding: 12px 16px;
  color: #000;
  font-weight: 600;
}

.profile_fields{
    padding: 12px 16px;
}

.profile_fields label {
    font-weight: 600;
    font-size: 14px;
    color: black;
    margin-left: 3px;
}

.profile_fields p{
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
    color: black;
    margin-left: 3px;
    text-align: left !important;
}

.update_btn {
    background: linear-gradient(45deg, rgb(186, 34, 113) 0%, rgb(130, 22, 73) 100%) !important;
    color: #fff;
    padding: 8px 15px;
    border: none;
    border-radius: 10px;
    margin-top: 15px;
  }

  .profile_pic_input{
    font-size: 12px;
    font-weight: 500;
    margin: 10px 0 0px 0;
}

.profile_picture{
  width: 130px;
  height: 130px;
  border-radius: 100%;
  object-fit: cover;
}