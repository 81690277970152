.main_location {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.location_bg {
    background-color: #000000c4;
    padding: 30px 50px 40px 50px;
    border-radius: 15px;
}

.location_bg h3 {
    text-align: center;
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 4px;
}

.location_bg p {
    font-size: 14px;
    font-weight: 300;
    text-align: center;
}

.form_input {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    padding: 3px 4px;
    width: 320px;
    margin-bottom: 10px;
}

.form_input_bar {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    margin-right: 10px;
    width: 270px;
    margin-bottom: 10px;
}

.form_input_bar input {
    border: none;
}

.form_input input {
    border: none;
    width: 100%;
}

.form_input input:focus-visible,
.form_input_bar input:focus-visible {
    outline: none !important;
}

.form_input input::placeholder,
.form_input_bar input::placeholder {
    font-size: 14px;

}

.form_input .search_icon {
    color: #b2b2b2;
    font-size: 20px;
    margin: 0 8px;
}

.form_input .location_icon {
    background-color: #ba2271;
    color: #fff;
}

.form_input img {
    width: 40px;
    cursor: pointer;
}

.find_hookah {
    background-image: url('../../../public/images/btn_bg.png');
    background-size: cover;
    padding: 13px 18px;
    font-size: 11px;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    text-transform: uppercase;
    margin-left: 15px;
}


.advance_search {
    margin-top: 10px;
}

.advance_search p {
    text-align: left;
    margin-bottom: 5px;
}


.form-check .form-check-input {
    background-color: transparent !important;
    border: 1px solid #fff !important;
    color: red !important;
}

.form-check {
    margin-right: 5px;
    width: 24px !important;
    height: 16px !important;
}

.form-check-label {
    font-size: 13px;
}

.dropdown-toggle {
    background-color: transparent !important;
    border: none !important;
    color: #00000085 !important;
    font-size: 15px !important;
}

.dropdown-toggle::after {
    margin-left: 15px !important;
}

.form-select {
    border: none !important;
    color: #0000009e !important;
    font-size: 15px !important;
}


.css-b62m3t-container {
    width: 100%;
    padding: 2px 1px;
}

.css-1s2u09g-control {
    border-width: 0 !important;
    cursor: pointer !important;
}

.css-26l3qy-menu {
    background-color: #fff !important;
    color: #000 !important;
}

.css-1pahdxg-control {
    border-width: 0 !important;
    cursor: pointer !important;
}

.location_form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.location_btn_div {
    margin-bottom: 10px;
}


@media (max-width: 767px) {

    .location_btn_div {
        text-align: center;
        width: 100%;
    }

    .name_input {
        width: 100% !important;
        margin-right: 0 !important;
        z-index: 0;
    }

    .name_input input {
        width: 100%;
    }

    .form_input {
        width: 100%;
    }

    .location_form {
        flex-wrap: wrap;
    }

    .location_bg {
        background-color: #000000c4;
        padding: 25px 10px;
        border-radius: 15px;
    }

    .form_input_bar {
        margin-right: 0px;
        width: 100%;
        margin-bottom: 10px;
    }

    .find_hookah {
        margin-top: 10px;
        margin-left: 0;
    }

    .home_slider h1::after,
    .browse_by h1::after {
        display: none;
    }

    .home_slider h1,
    .browse_by h1 {
        font-size: 25px;
    }

    .browse_col {
        margin-bottom: 15px;
    }

    .main_location {
        margin: 10px;
    }
}