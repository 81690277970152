.navbar {
  background-color: #fff;
}

.navbar-brand img {
  width: 110px;
  cursor: pointer;
}

.nav-link {
  color: #282828 !important;
  font-size: 14px !important;
  margin-right: 8px;
  margin-bottom: 0;
  cursor: pointer;
}

.sign_btn {
  background-image: url("../../../public/images/btn_bg.png");
  background-size: cover;
  padding: 12px 18px;
  font-size: 11px;
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
}

.sign_btn svg {
  font-size: 14px;
  margin-right: 7px;
  margin-bottom: 2px;
  font-weight: bold;
}

.navbar_text {
  margin-bottom: 0;
  text-align: center;
  padding: 10px 0;
  background-color: #000;
  color: #ffff;
}

.navbar_text span {
  color: #ba2271;
  font-weight: 700;
  cursor: pointer;
}

.cart_link svg {
  font-size: 25px;
  margin: 0 10px;
}

.cart_link {
  color: #ba2271;
  text-decoration: none;
  cursor: pointer;
}

sup {
  font-size: 18px !important;
  position: relative !important;
  left: -5px !important;
}

.cart_link:hover {
  color: #ba2271;
  text-decoration: none;
}

a#basic-nav-dropdown {
  font-weight: bold !important;
}

a.dropdown-item {
  color: #ba2271;
}

.login_nav {
  display: flex;
  align-items: center;
}

.login_nav img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-left: 15px;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 1200px) {
  .navbar .container {
    max-width: 1060px;
  }
}

@media (max-width: 767px) {
  .cart_link {
    color: #ba2271;
    text-decoration: none;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}