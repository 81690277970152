.payment-plan-main {
  background-color: #f5fafe;
}
.payment-plan_img_container {
  position: relative;
}

.payment-plan_img_container h2 {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 40%;
  font-size: 40px;
  text-align: center;
  color: white !important;
  font-weight: 700;
}

.table_div {
  margin-top: 20px;
  background-color: #fff;
}

.table_div h4 {
  background-color: #971b59;
  color: #fff;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 0;
}

.check_icon {
  font-size: 25px;
  color: green;
}

.cross_icon {
  font-size: 25px;
  color: #ff0000ba;
}

.purchase_btn {
  background: linear-gradient(
    45deg,
    rgb(186, 34, 113) 0%,
    rgb(130, 22, 73) 100%
  ) !important;
  color: #fff;
  padding: 8px 15px;
  border: none;
  border-radius: 10px;
}

.price_tag {
  font-size: 30px;
  font-weight: 700;
  color: #bf3d7d;
}

@media (max-width: 767px) {
  .payment-plan_img_container h2 {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 30%;
    font-size: 16px;
    text-align: center;
    color: white !important;
    font-weight: 700;
  }

  .table_div h4 {
    font-size: 15px;
  }
}
