.advertise_main h4{
    color: #ba2271;
    font-size: 27px;
    font-weight: 600;
}

.advertise_main h6{
    font-weight: 600;
}

.advertise_main .listings_li{
    list-style-type: auto;
    margin-bottom: 40px;
}