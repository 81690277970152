.order_placed{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 0;
}

.order_placed img{
    width: 200px;
    background-color: #c9edc9;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
}

.order_placed h1{
    margin: 20px 0;
    color: #ba2271;
    font-weight: 800;
}