.table td img{
    width: 50px;
    border-radius: 8px;
    height: 30px;
    object-fit: cover;
}

.amneity_table_image img{
    width: 20px !important;
    height: 20px !important;
}

.table td{
    vertical-align: middle;
}

.table_icons svg{
    font-size: 20px;
    margin-right: 10px;
    color: #971B59;
    cursor: pointer;
}

.detailed_eye{
    width: 25px !important;
    height: 15px !important;
    margin-right: 10px;
    object-fit: contain !important; 
    cursor: pointer;
}