@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
}

span.error_msg {
  font-size: 11px;
  color: red;
}

.active~.page_responsive {
  width: calc(100% - 180px);
  left: 180px;
  transition: all 0.2s ease;
  /* overflow-x: hidden; */
}

.page_responsive {
  position: absolute;
  /* top: 30px; */
  padding-left: 8%;
  padding-right: 3%;
  padding-top: 1%;
  height: 100%;
  /* background-color: #f8faff; */
  width: calc(100% - 74px);
  left: 74px;
  transition: all 0.2s ease;
  /* overflow-x: hidden; */
}

.page_responsive .main_heading {
  color: #394767;
  /* font-style: italic; */
  font-size: 28px;
}

.btn_details {
  background: linear-gradient(45deg, rgb(186, 34, 113) 0%, rgb(130, 22, 73) 100%) !important;
  color: #fff;
  border-radius: 5px;
  padding: 3px 15px;
  border: none;
}

.unsubs,
.rejected {
  background-color: #f6dfdf;
  color: red !important;
  font-size: 13px !important;
  padding: 5px 15px !important;
  border-radius: 30px;
  font-weight: 600;
}

.pending {
  background-color: #fde4c1;
  color: #ff9600 !important;
  font-size: 13px !important;
  padding: 5px 15px !important;
  border-radius: 30px;
  font-weight: 600;
}

.approved {
  background-color: #cdf3d4;
  color: #32c049 !important;
  font-size: 13px !important;
  padding: 5px 15px !important;
  border-radius: 30px;
  font-weight: 600;
}

.completed {
  background-color: #d2e6d5;
  color: #118524 !important;
  font-size: 13px !important;
  padding: 5px 15px !important;
  border-radius: 30px;
  font-weight: 600;
}

.banned {
  background-color: #f443367a;
  color: #eb1b0c !important;
  font-size: 13px !important;
  padding: 5px 15px !important;
  border-radius: 30px;
  font-weight: 600;
}

.dashboard_heading_h1 {
  color: #911954;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 800;
  /* font-style: italic; */
  margin-bottom: 10px;
  margin-top: 0px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  /* box-shadow: rgb(0 0 0 / 21%) 0px 4px 12px; */
}

.pac-container {
  z-index: 10000000 !important;
}

/* .fade.site_modal.modal.show {
  z-index: 9999 !important;
} */

.MuiClockPicker-root {
  z-index: 9999999999999999 !important;
}

.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
  z-index: 999999999 !important;
}

.view_orders_customer {
  font-size: 13px;
  border: 1px solid #971b59;
  padding: 2px 10px;
  border-radius: 20px;
  color: #971b59;
  cursor: pointer;
}

.table>:not(caption)>*>* {
  border-right-width: 1px !important;
  border-left-width: 1px !important;
}

.modal{
  z-index: 99999 !important;
}