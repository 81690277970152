.faq_main {
    margin: 30px 0;
}

.faq_main h3 {
    font-weight: 600;
    color: #000;
    margin-bottom: 25px;
    font-size: 35px;
}

.faq_accordion .accordion-item {
    margin-bottom: 0;
}

.faq_accordion .accordion-button {
    color: #ba2271 !important;
    background-color: #fff !important;
    box-shadow: rgb(60 64 67 / 0%) 0px 1px 2px 0px, rgb(60 64 67 / 5%) 0px 2px 6px 2px !important;
    font-weight: 500 !important;
}