.create_Shop_form {
  background-color: #F5FAFE;
  border-top: 8px solid #ba2271;
  margin: 20px 0;
  border-radius: 10px 10px 0 0;
  padding: 20px 0;
  background-repeat: no-repeat;
}

.create_shop_right_section button {
  background-color: transparent;
  color: black;
  border: none;
  text-align: start;
}

.social_btn {
  width: 100%;
  padding: 5px;
  background: transparent;
  border: none;
}

.social_btn svg {
  font-size: 30px;
  color: #ba2271;
}

.delete_btn svg {
  font-size: 20px;
  color: #ba2271;
  margin-left: 40px;
  cursor: pointer;
}

.activeTab {
  background: linear-gradient(45deg,
      rgb(186, 34, 113) 0%,
      rgb(130, 22, 73) 100%);
  color: white !important;
}

.create_shop_right_section button:active,
.create_shop_right_section button:hover {
  border-radius: 10px;
  background: linear-gradient(45deg,
      rgb(186, 34, 113) 0%,
      rgb(130, 22, 73) 100%);
  border: none;
}

.create_shop_right_section button.disable:hover {
  color: #808080e0 !important;
  cursor: auto;
  background: transparent !important;
}

.social_show {
  display: flex;
  list-style-type: none;
  margin: 10px 0;
  padding-left: 0;
  border-bottom: 1px solid #0000001f;
  padding-bottom: 5px;
}

.social_show li {
  margin-right: 15px;
  color: #000;
}

button.disable {
  color: #808080e0 !important;
  cursor: auto;
}

.tab_container h6 {
  color: #ba2271;
  text-transform: uppercase;
  position: relative;
}

.tab_container h6::before {
  content: '';
  width: 100%;
  background-color: #ba2271;
  height: 1px;
  position: absolute;
  top: 10px;
}

.tab_container h6 span {
  background-color: #F5FAFE;
  position: relative;
  display: inline-block;
  padding-right: 15px;
  font-size: 20px;
  /* padding-bottom: 10px; */
}

.tab_container {
  margin: 35px 0;
}

.MuiOutlinedInput-root {
  background-color: #fff;
}

.MuiOutlinedInput-root input {
  background-color: #fff !important;
  margin-top: 0px !important;
  height: 12px !important;
  border: none !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #cccccc !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #000000ad !important;
  margin-top: -3px !important;
}

.css-p0rm37.Mui-error {
  color: #cccccc !important;
}

.css-segi59.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #cccccc !important;
}

/* .time_picker{ */
/* margin-top: 4px !important; */
/* border: none !important; */

/* border: "none !important",
      "& .MuiOutlinedInput-root": {
        border: "0px solid rgba(235,235,235,90%) !important",
        borderRadius: "8px !important",
      },
      "& .MuiOutlinedInput-input": {
        padding: "10.5px 14px !important",
        backgroundColor: "#F3F8FE",
        border: "none !important",
        fontSize: "14px !important",
        // border: "1px solid #EBEBEB !important"
      }, */
/* } */

.create_shop_form label {
  font-weight: 400;
  font-size: 14px;
  color: black;
  margin-left: 5px;
  cursor: pointer;
}

.shop_loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.shop_loading h5 {
  font-size: 25px;
  font-weight: bold;
}

#OpenTime:before {
  content: 'Open:';
  margin-right: .6em;
  color: #9d9d9d;
}

#CloseTime:before {
  content: 'Close:';
  margin-right: .6em;
  color: #9d9d9d;
}

.form_inputs input {
  width: 100%;
  height: 45px;
  padding-left: 10px;
  border: 1px solid #cccccc;
  font-size: 14px;
  border-radius: 5px;
}

.create_shop_form textarea {
  width: 100%;
  padding-left: 10px;
  border: 1px solid #cccccc;
  font-size: 14px;
  border-radius: 5px;
}

.create_shop_form select {
  width: 100%;
  height: 40px;
  padding: 5px 0;
  padding-left: 10px;
  margin-bottom: 5px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 14px;
  color: black;
}

.create_shop_form input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border: none !important;
  border-color: #ba2271 !important;
}

.create_shop_right_section {
  position: relative;
}

.shops_tab_btn {
  position: sticky;
  width: 17rem;
  top: 10px;
}


.question_div {
  margin-top: 20px;
}

.delete_icon svg {
  color: #ba2271;
  margin-bottom: 20px;
  font-size: 25px;
  cursor: pointer;
}

.amenities {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.amenities div {
  display: flex;
}

.amenities label {
  font-size: 14px;
}

.thumb_image_container {
  height: 100%;
}

.thumb_image_container .thumb_banner_Image {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  /* height: 520px; */
}

.thumb_image {
  width: 100%;
  height: 230px;
  border-radius: 15px;
  border: 1px solid rgb(192, 189, 189);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumb_image_gallery {
  width: 100%;
  border-radius: 15px;
  border: 1px solid rgb(192, 189, 189);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.thumb_image_gallery2 {
  width: 100%;
  border-radius: 15px;
  /* border: 1px solid rgb(192, 189, 189); */
  /* background-color: white; */
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  /* padding: 50px; */
}

.thumb_image2 {
  width: 100%;
  height: 230px;
  border-radius: 15px;
  border: 1px solid rgb(192, 189, 189);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumb_image label,
.thumb_image_gallery label {
  margin-left: 0;
  /* width: 70%; */
}

.thumb_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  border-radius: 15px;
  cursor: pointer;
  max-width: 150px;
}

.thumb_image_gallery .col-md-3 {
  flex: auto !important;
  max-width: 25%;
  text-align: center;
}

.thumb_image_gallery>label {
  width: 100% !important;
}

.preview_gallery_img {
  /* width: 100% !important; */
  object-fit: cover !important;
  object-position: center !important;
  border-radius: 15px !important;
  cursor: pointer !important;
  height: 180px !important;
  margin-top: 10px !important;
}

.thumb_image2 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  border-radius: 15px;
  cursor: pointer;
  max-height: 200px;
}

.thumb_image_container p {
  font-size: 12px;
  text-align: center;
}

.gallery_images {
  border-radius: 15px;
  border: 1px solid rgb(192, 189, 189);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
}

.preview_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  border-radius: 15px;
  cursor: pointer;
  max-width: 200px;
  margin-bottom: 15px;
}


.gallery_images label img {
  cursor: pointer;
}

.create_btn {
  background-image: url('../../../../public/images/btn_bg.png');
  background-size: cover;
  padding: 10px 30px;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
}

.pre_div {
  position: relative;
}

.pre_div svg {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 3px 10px;
  color: #fff;
  background-color: #ba2271;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

/* .pre_div.col-md-3 {
  flex: auto !important;
} */
/* 
.active~.page_responsive .pre_div svg{
  right: 40px
} */

.preview_gallery_img2{
  width: 100% !important;
  object-fit: cover !important;
  object-position: center !important;
  border-radius: 15px !important;
  cursor: pointer !important;
  height: 180px !important;
  margin-top: 10px !important;
}


.preview_gallery_img_view{
  object-fit: cover !important;
  object-position: center !important;
  border-radius: 15px !important;
  cursor: pointer !important;
  height: 180px !important;
  margin-top: 10px !important;
  padding: 45px !important;
  width: 100%;
  border: 1px solid #0000002e !important;
}