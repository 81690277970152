.notFound_container {
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    background: linear-gradient(180deg, #495057, #C0C3C6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.notFound_box {
    width: 80vw;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}


.notFound_box button{
    background-image: url('../../../../public/images/btn_bg.png') !important;
    background-size: cover;
    padding: 13px 18px;
    font-size: 11px;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    text-transform: uppercase;
    margin-left: 15px;
}

.heading_404 {
    font-size: 130px;
    font-weight: 800;
    color: #ba2271;
}

@media screen and (max-width:767px) {}