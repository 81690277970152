.main_login {
  width: 100vw;
  max-width: 100%;
  margin: 50px 0;
}

.login_container {
  background-image: url("../../../../../public/images/signUpBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.login_left_section {
  width: 100%;
  height: 100%;
}

.login_img {
  width: 80%;
  height: auto;
}

.login_right_section label {
  font-weight: 600;
  font-size: 14px;
  color: black;
  margin-left: 3px;
}

.login_right_section h6 {
  font-weight: 600;
  font-size: 14px;
  color: black;
  margin-left: 3px;
  margin-top: 5px;
}

.login_right_section button {
  border-radius: 10px;
  background: linear-gradient(45deg, rgb(186, 34, 113) 0%, rgb(130, 22, 73) 100%) !important;
  box-shadow: 0px 30px 20px 0px rgba(0, 0, 0, 0.1);
  border: none;
}

.signup_input {
  position: relative;
}

.signup_input img {
  position: absolute;
  top: 12px;
  left: 10px;
}

.signup_input svg{
  position: absolute;
  top: 8px;
  left: 9px;
  color: #cccccc;
  font-size: 27px;
}

.login_right_section input {
  width: 100%;
  height: 45px;
  padding-left: 45px;
  border: 1px solid #cccccc;
  font-size: 14px;
  border-radius: 5px;
}

.login_right_section select {
  width: 100%;
  height: 40px;
  padding: 5px 0;
  padding-left: 10px;
  margin-bottom: 5px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 14px;

  color: gray;
}

.main_error_msg{
  text-align: center;
  padding: 12px 0;
  background-color: #f9afaf;
  color: #ff0000cf;
  border-radius: 10px;
  font-size: 18px;
  margin: 15px;
}

@media screen and (max-width:1024px) {
  .login_right_section input {
    font-size: 12px;
  }

  .login_container {
    background-size: contain;
  }

  .main_login {
    padding: 15px;
  }
}