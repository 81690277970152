.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  /* background-color: white; */
  /* background-color: #171717 !important; */
  background-color: #353b43 !important;
  width: 74px;
  /* box-shadow: rgb(231 29 136 / 70%) 0px 1px 2px 0px, rgb(225 37 122 / 3%) 0px 1px 3px 1px; */
  z-index: 99;
  transition: all 0.4s ease;
  /* border-right: 8px solid #911954; */
}

.nav_list {
  /* padding: 10px 0 0 0;
  padding-top: 10px;
  height: 100vh;
  overflow-y: scroll; */

  background-color: #353b43 !important;
  height: 70vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 40px 10px 10px;
}

.nav_list::-webkit-scrollbar {
  width: 5px !important;
}

.nav_list::-webkit-scrollbar-thumb {
  background: #fff !important;
  border-radius: 10px !important;
  outline: 1px solid #fff !important;
}

.nav_list li a {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  padding: 15px 5px;
  transition: all 0.2s ease;
  margin-bottom: 5px;
  font-weight: 400;
}

.nav_list li:hover {
  cursor: pointer;
  /* background-color: #e9ecef; */
  background-color: #f2f5f7;
  color: black !important;
}

.nav_list li {
  transition: all 0.3s ease;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.nav_list li a:hover {
  color: black !important;
}

.nav_list li a span {
  margin-left: 12px;
  opacity: 0;
}

.nav_list li:hover a span {
  margin-left: 12px;
  opacity: 0;
  color: #000;
}

.nav_list li a svg {
  transform: translateX(6px);
  min-width: 20px;
  font-size: 18px;
  /* color: #911954; */
  color: #fff;
}

.nav_list li:hover svg {
  color: grey !important;
}

.profile {
  color: #f3f3f3;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 36px 0px;
  opacity: 0;
  justify-content: center;
  margin-top: 20px;
}

.profile p {
  padding: 20px;
  border-radius: 0 0 70px 70px;
}

.profile img {
  width: 140px;
  object-fit: cover;
}

.sidebar.active .logo_content {
  height: 100%;
  max-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #17191f;
    box-shadow: 0px 15px 20px 0px rgba(40, 126, 254, 0.15); */
}

.logo_content {
  height: 100%;
  max-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #17191f;
    box-shadow: 0px 15px 20px 0px rgba(40, 126, 254, 0.15); */
}

.logo_content .fa-bars {
  color: #fff;
  position: absolute;
  left: 50%;
  font-size: 16px;
  text-align: center;
  top: 23px;
  cursor: pointer;
  transform: translateX(-50%);
  transition: all 0.5s ease;
}

.sidebar.active {
  background: #fff;
  /* border-bottom-right-radius: 60px; */
  /* border-top-right-radius: 60px; */
  /* border-right: 8px solid #911954; */
  width: 240px;
}

.nav_active,
.nav_list li.nav_active:hover {
  /* background-color: #17191f !important; */
  color: white !important;

  background: linear-gradient(45deg, rgb(186, 34, 113) 0%, rgb(130, 22, 73) 100%) !important;
  /* box-shadow: 0px 30px 20px 0px rgb(0 0 0 / 10%); */
}

.sidebar.active .profile {
  opacity: 1;
}

.sidebar.active .nav_list li a span {
  opacity: 1;
}

.sidebar.active .logo_content .fa-bars {
  left: 85%;
  top: 4%;
  color: #fff;
  font-size: 15px;
}

.nav_active {
  background-color: #17191f !important;
}

.nav_active a svg,
.nav_active a span {
  color: #f3f3f3 !important;
  font-weight: 600;
}

.sidebar::-webkit-scrollbar {
  width: 5px;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
  outline: 1px solid #fff;
}

.dashboard_nav {
  margin-left: auto;
}

/* Bread Crumb */

.breadcrumb_main {
  background: #d8dce3;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.breadcrumb_container {
  width: 64%;
  position: relative;
  float: left;
  display: flex;
}

.breadcrumb_container h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: black;
}