.owners_tab{
    background: linear-gradient(45deg, rgb(186, 34, 113) 0%, rgb(130, 22, 73) 100%) !important;
}

.owners_tab ul{
    margin: 0;
    color: #fff;
    list-style-type: none;
    display: flex;
    padding: 15px;
    align-items: center;
}

.owners_tab ul li{
    margin-right: 30px;
    text-transform: uppercase;
    font-size: 16px;
    cursor: pointer;
}

.owners_tab ul li.active{
    background-color: #fff;
    color: #9B1C5B;
    padding: 5px 13px;
    border-radius: 7px;
    font-weight: 600;
}

.upgrade{
    background-image: url('../../../../../public/images/upgrade_bg.png');
    background-size: cover;
    padding: 100px 70px 130px 70px;
    margin-bottom: 10px;
    margin-top: 30px;
}

.upgrade h1{
    font-weight: 300;
}

.upgrade h1 span{
    font-weight: 700;
}

.upgrade button {
    background: linear-gradient(45deg, rgb(186, 34, 113) 0%, rgb(130, 22, 73) 100%) !important;
    color: #fff;
    padding: 8px 15px;
    border: none;
    margin-top: 20px;
    border-radius: 10px;
}

.main_boxes{
    padding: 40px 40px;
}

.main_boxes h2{
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 15px;
}

.main_box{
    border-top: 15px solid #9B1C5B;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 15px;
    margin-bottom: 20px;
}

.main_box ul{
    font-size: 20px;
}

.main_box ul li{
    color: #9B1C5B;
    font-weight: 700;
    text-transform: uppercase;
}

.main_box h5{
    text-align: end;
    font-size: 35px;
    font-weight: 100;
}


.table_view_btn button{
    background-color: #971b59;
    width: 100%;
    color: #fff;
    border: none;
    padding: 5px;
    border-radius: 10px;
    font-size: 14px;
}

.business_list h5{
    text-align: center;
    margin: 20px 0;
    font-weight: 700;
}

.business_list h5 span{
    color: #971b59;
    cursor: pointer;
}


@media (max-width: 767px) {
    .owners_tab ul {
        flex-wrap: wrap;
        justify-content: center;
    }

    .upgrade{
        padding: 60px 0px 80px 40px;
    }
}