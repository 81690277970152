.payment_method_bg {
    background-color: #f4faff;
    padding-top: 20px;
}


.payment_details h1 {
    padding: 20px 0;
    color: #282828;
    font-weight: 700;
    position: relative;
    overflow: hidden;
    font-size: 32px;
}

.payment_card {
    border-width: 1px;
    border-color: rgb(204, 204, 204);
    border-style: solid;
    border-radius: 10px;
    position: relative;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 30px 20px 0px rgba(0, 0, 0, 0.2);
    padding: 10px 20px;
    margin-bottom: 50px;
    border-top: 20px solid #bf3d7d;
}

.selected_package {
    border-width: 1px;
    border-color: rgb(204, 204, 204);
    border-style: solid;
    border-radius: 10px;
    position: relative;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 30px 20px 0px rgb(0 0 0 / 20%);
    padding: 0;
    margin-bottom: 50px;
}

.selected_package h4{
    background: linear-gradient(45deg, rgb(186, 34, 113) 0%, rgb(130, 22, 73) 100%);
    color: #fff;
    margin-bottom: 0;
    padding: 15px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: 800;
}

.selected_package .package_text{
    padding: 8px 20px 15px 20px;
}

.selected_package .package_text p{
    font-size: 20px;
    margin-bottom: 0;
    border-bottom: 1px solid #0000001f;
    font-style: italic;
    font-weight: 500;
}

.selected_package .package_text ul{
    list-style-type: none;
    padding-left: 0;
    padding: 15px 0 5px;
    margin-bottom: 0;
    border-bottom: 1px solid #0000001f;
}

.selected_package .package_text ul li{
    padding-bottom: 7px;
    font-size: 16px;
    color: #000000b8;
}

.total_div{
    display: flex;
    justify-content: space-between;
    margin: 15px 0 0;
}

.total_div h5{
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 0;
}

.total_div h6{
    color: #bf3d7d;
    margin-bottom: 0;
    font-size: 25px;
    font-weight: 700;
}

.payment_card h5 {
    color: #bf3d7d;
    padding: 15px 0px;
    font-weight: 600;
}

.main_paypal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid rgb(199, 199, 199);
    border-radius: 10px;
    margin-bottom: 10px;
}

.paypal_container,
.stripe_container {
    display: flex;
    gap: 10px;
    align-items: center;

}

.paypal_container img,
.stripe_container img {
    max-width: 100%;
    object-fit: contain;
}

.paypal_container p,
.stripe_container p {
    margin-bottom: 0;
    color: #8d8d8d;
    font-size: 14px;
}

.payment_terms_condition {
    display: flex;
    padding: 10px 0px;
    align-items: center;
    justify-content: space-between;
}

input[type='radio'] {
    accent-color: #bf3d7d;
    position: relative;
    top: 3px;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.payment_terms_condition p {
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    color: #1a1a1a;
}

#pay_now{
    padding: 14px 50px !important;
    box-shadow: 0px 30px 20px 0px rgba(0, 0, 0, 0.2);
}



  