.shop_details h1 {
    padding: 40px 0;
    color: #000;
    font-weight: 700;
    position: relative;
    overflow: hidden;
}

.shop_details h1::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    color: #000;
    background-color: #ba2271;
    margin-left: 35px;
    top: 48%;
}

.map_section {
    background-color: #f5fafe;
    padding: 20px;
    border-radius: 10px;
}

.shop_view_time {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.shop_view_time p {
    margin-bottom: 0;
    font-size: 13px;
    margin-left: 10px;
}

.shop_view_btns {
    display: flex;
    justify-content: space-around;
    margin-top: 25px;
}

.coupon_btns button {
    padding: 7px 45px;
    background-color: #ba2271;
    color: #fff;
    border: none;
    font-weight: 600;
    margin-right: 15px;
    margin-top: 10px;
}

.coupon_code {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: fit-content;
    margin: auto;
    padding: 10px;
    border: 1px solid #00000040;
    border-radius: 50px;
    background-color: #0000000f;
}

.coupon_code h5 {
    margin-bottom: 0;
    margin-right: 30px;
    font-size: 25px;
    font-weight: 900;
}

.copy_coupons {
    background-color: #ba2271 !important;
    padding: 10px 35px !important;
    font-size: 13px !important;
    color: #fff !important;
    font-weight: 600 !important;
    border: none !important;
    border-radius: 30px !important;
    text-transform: uppercase !important;
    margin-top: 0px !important;
}

.coupon_detail {
    border-top: 1px solid #00000029;
    margin-top: 20px;
    padding-top: 15px;
}

.coupon_detail ul li {
    display: flex;
    margin-top: 15px;
    padding-left: 8px;
}

.coupon_detail p {
    font-size: 15px !important;
    text-align: left !important;
    margin-bottom: 0 !important;
    margin-right: 20px !important;
}

.coupon_detail p span {
    font-weight: 800;
}

.signup_input {
    position: relative;
}

.signup_input img {
    position: absolute;
    top: 12px;
    left: 10px;
}

.signup_input input {
    width: 100%;
    height: 45px;
    padding-left: 45px;
    border: 1px solid #cccccc;
    font-size: 14px;
    border-radius: 5px;
}

.signup_input textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    font-size: 14px;
    border-radius: 5px;
}

.enquiry_btn{
    border-radius: 10px;
    background: linear-gradient(45deg, rgb(186, 34, 113) 0%, rgb(130, 22, 73) 100%) !important;
    box-shadow: 0px 30px 20px 0px rgb(0 0 0 / 10%);
    border: none !important;
}

.shop_view_btns .call_us,
.shop_view_btns .book_now {
    width: 100%;
    margin-right: 20px;
    border-radius: 5px;
    background-color: #b53070;
    color: #fff;
    border: none;
    text-transform: uppercase;
    font-size: 14px;
    padding: 10px 0;
    font-weight: 700;
}

.shop_view_btns .book_now {
    background-color: #fff;
    color: #b53070;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.reviews_section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
}

.reviews_section p {
    margin-bottom: 0;
    font-size: 13px;
    margin-left: 7px;
}

.details {
    width: 100%;
    border-top: 1px solid #02020287;
    border-bottom: 1px solid #02020287;
    padding: 10px 0;
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.details h5 {
    margin-bottom: 0;
    font-size: 28px;
    font-weight: 700;
    color: #000;
}

.details .serve {
    display: flex;
    margin-left: 35px;
}

.details .serve img {
    width: 25px;
    height: 25px;
}

.details .serve p {
    margin-bottom: 0;
    font-size: 15px;
    margin-left: 5px;
    padding-top: 2px;
}

.detail_text {
    margin-top: 18px;
    color: #000;
    font-size: 14px;
    padding-bottom: 6px;
    border-bottom: 1px solid #02020287;
}

.menu_main h5 {
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: 700;
    color: #000;
}

.menu_main {
    padding: 20px 0;
    border-bottom: 1px solid #02020287;
}

.menu_main h6 {
    margin-bottom: 0;
    margin-top: 8px;
    color: #000000b8;
}

.menu_main p {
    color: #000;
    margin-bottom: 0;
    font-size: 17px;
    font-weight: 600;
    margin: 10px 0;
}

.frequently_question h5 {
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: 700;
    color: #000;
    margin-top: 20px;
    margin-bottom: 20px;
}

.pre_forward button {
    border: none;
}

.pre_forward button img {
    width: 30px;
    margin-right: 10px;
}

.accordion-button {
    background-color: #f5fafe !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #000 !important;
}

.accordion-item {
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.accordion-collapse {
    background-color: #f5fafe !important;
}

.accordion-body {
    font-size: 14px;
}

.accordion-button::after {
    background-image: url('../../../../../public/images/plus.png') !important;
    width: 12px !important;
    height: 12px !important;
    background-position: center;
    background-size: cover !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url('../../../../../public/images/minus.png') !important;
    height: 2px !important;
}

.frequently_question {
    padding: 20px 0;
    border-bottom: 1px solid #02020287;
}

.testimonials {
    padding: 20px 0;
}

.testimonials img {
    width: 65px;
    height: 65px;
    border-radius: 100px;
    object-fit: cover;
    object-position: center;
}

.testimonials h6 {
    margin-bottom: 2px;
    color: #000;
    font-weight: 600;
    margin-left: 10px;
}

.testimonials p {
    font-size: 15px;
    margin-bottom: 0;
    margin-left: 10px;
}

.testimonials p span {
    font-size: 13px;
    color: #000000b8;
}


.gallery {
    padding: 20px 0;
}

.gallery h5 {
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: 700;
    color: #000;
    margin-top: 20px;
    margin-bottom: 20px;
}

.testimonials h5 {
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: 700;
    color: #000;
    margin-top: 20px;
    margin-bottom: 30px;
}

.timings h5 {
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: 700;
    color: #000;
    margin-bottom: 40px;
}

.timings p {
    margin-bottom: 7px;
}

.gallery img {
    border-radius: 10px;
    width: 100%;
    height: 180px;
    object-fit: cover;
    object-position: center;
}

.shop_view_time svg {
    color: #b53070;
}

.banner_container img {
    width: 100%;
    /* object-fit: cover; */
    height: 400px;
    object-position: center;
}

.enquiry_section {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin: 25px 0;
    border-radius: 10px;
    padding: 15px;
}

.enquiry_section h3 {
    font-weight: 500;
    font-size: 25px;
}

.enquiry_section h3 span {
    color: #b53070;
    font-weight: 700;
}

.testimonial_stars {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.review_row {
    border-bottom: 1px solid #0000002e;
    padding-bottom: 10px;
    margin-bottom: 15px;
    align-items: center;
}

.social_icons svg {
    font-size: 20px;
    margin-right: 10px;
}

.social_icons .fb {
    color: #4267B2;
}

.social_icons .insta {
    color: #DD2A7B;
}

.social_icons .twitter {
    color: #00acee;
}

.social_icons a {
    text-decoration: none;
}

.testimonials button {
    border-radius: 10px;
    background: linear-gradient(45deg, rgb(186, 34, 113) 0%, rgb(130, 22, 73) 100%) !important;
    box-shadow: 0px 30px 20px 0px rgb(0 0 0 / 10%);
    border: none;
    padding: 5px 25px;
}

.testimonials label {
    font-weight: 600;
    color: #000;
}

canvas.react-pdf__Page__canvas {
    width: 660px !important;
    height: 630px !important;
}

.modal_child img {
    width: 90px;
    height: 50px;
    object-fit: contain;
}

.modal_child h6 {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    padding: 0 25px;
    margin-bottom: 15px;
}

.edit_resize{
    text-align: end;
    position: absolute;
    right: 0px;
    bottom: 0;
}

.banner_container{
    position: relative;
}



@media (max-width: 767px) {
    .find_shop_row {
        flex-direction: column-reverse;
    }

    .shops {
        margin-right: 0;
    }

    .shop_details h1 {
        padding: 20px 0 20px 0;
        font-size: 25px;
        margin-bottom: 0;
    }

    .reviews_section {
        display: block;
    }

    .shop_view_time {
        margin-top: 0;
    }

    .details .serve {
        display: flex;
        margin-left: 25px;
        margin-right: 17px;
        align-items: center;
    }

    .details {
        overflow: scroll;
    }

    .menu_div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 20px;
    }

    .menu_main h6 {
        font-size: 20px;
    }

    .menu_main p {
        font-size: 18px;
    }

    .testimonial_img {
        text-align: center;
    }

    .testimonial_text {
        text-align: center;
    }

    .testimonial_stars {
        justify-content: center;
    }

    .details .serve p {
        font-size: 9px;
    }

    .main_map img {
        width: 100%;
    }
}