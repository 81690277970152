.enquiry_details{
    padding: 25px;
}

.back_btn{
    background-image: url('../../../../../../public/images/btn_bg.png');
    background-size: cover;
    padding: 10px 30px;
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    text-transform: uppercase;
    text-decoration: none !important;
}

.back_btn:hover{
    color: #fff !important;
}