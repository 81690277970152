.location_img img {
  width: 35px;
  cursor: pointer;
  padding-left: 5px;
}


@media (max-width: 767px) {
  .location_img img {
    text-align: center;
  }
}
