.user_icon,
.listing_icon,
.order_icon,
.dollar_icon {
    background-color: #1161FA;
    width: fit-content;
    padding: 20px 25px;
    border-radius: 5px;
}

.dollar_icon img,
.user_icon img,
.listing_icon img,
.order_icon img {
    width: 25px;
    height: 40px;
    object-fit: contain;
}

.user_icon svg {
    font-size: 40px;
    color: #fff;
    width: 25px;
    height: 35px;
}

.order_icon {
    background-color: #19D296;
}

.listing_icon {
    background-color: #002E85;
}

.dollar_icon {
    background-color: #5ca951;
}

.user_icon img {
    width: 25px;
}

.listing_icon img {
    width: 25px;
}

.order_icon img {
    width: 25px;
}

.dashboard_div {
    background-color: #664be0;
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 15%) 0px 4px 12px;
    cursor: pointer;
    height: 130px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}


.dashboard_div h5 {
    color: #fff;
    /* font-style: italic; */
    font-size: 18px;
    margin-bottom: 5px;
}

.dashboard_div p {
    margin-top: 10px;
    margin-bottom: 0;
    color: #fff;
    font-size: 16px;
}

.dashboard_div h6 {
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0;
}

.dashboard_div button {
    background-image: url('../../../../../public/images/btn_bg.png');
    background-size: cover;
    padding: 5px 0px;
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    text-decoration: none !important;
}