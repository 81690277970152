.footer-container{
    background-image: url("../../../public/images/footerBackground.png");
    width:100vw;
    max-width: 100%;
}
.footer-about{
    font-size: 14px;
}
.footer-about > p {
    padding-right: 40px !important;
}
.news-letter{
    position: relative;
}
.news-letter >input{
    padding: 0 10px ;
    width:100%;
    height:50px;
    border-radius: 8px;
    border: none;
}

.news-letter input{
    outline: none;
}

.news-letter > img{
    position: absolute;
    right: 10px;
    top: 10px;
}
.social-connect{
    gap: 15px;

}
.footer-links p{
    font-size: 14px;
    margin-bottom: 0.8em !important;
}
.social-connect div{
    width:45px;
    height:45px;
    border-radius:15px;
    background-color:white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.social-connect div img {
    width: 13px;
    height: 15px;
    object-fit: contain;
}

.footer-container > hr{
    opacity: 1 !important;
}
.payment > img {
    margin-bottom: 1em;
    padding-left: 1em;
}
.copyrights{
    font-size: 14px !important;
}

.footer-links p a{
    color: #fff;
    text-decoration: none !important;
}