.checkout_section{
    background-color: #f5fafe;
}

.main_checkout{
    width: 100%;
    margin: 50px 0;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding-bottom: 30px;
}

.main_checkout h5{
    background-color: #9B1C5B;
    color: #fff;
    padding: 10px;
    margin: 0;
    border-radius: 10px 10px 0 0;
    font-size: 17px;
}

.checkout_text_section{
    border-top: 20px solid #9B1C5B;
    margin: 30px 20px;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.checkout_products{
    background-color: #f5fafe;
    padding: 15px 20px;
}

.checkout_products h6{
    border-bottom: 1px solid #0000002e;
    padding-bottom: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
}

.checkout_products img {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}

.checkout_products p{
    margin-bottom: 0;
    font-size: 15px;
}

.checkout_products h4{
    font-size: 14px;
    font-weight: 400;
}

.product_detail{
    border-bottom: 1px solid #0003;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 15px;
}

.product_detail h4 span{
    font-weight: 600;
}

.product_detail p:first-child{
    font-weight: 600;
}

.total_section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 15px;
}

.total_section h4{
    margin-bottom: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 900;
}

.total_section p{
    font-weight: 800;
}

.sub_total{
    border-bottom: 1px solid #0003;
}

.main_total{
    display: flex;
    justify-content: space-between;
    margin: 10px 15px;
    align-items: center;
}

.main_total h3{
    margin-bottom: 0;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
}

.main_total p{
    font-weight: 800;
    font-size: 20px;
}

.place_order{
    text-align: center;
    margin: 20px;
}

.place_order button {
    background-size: cover;
    padding: 12px 18px;
    font-size: 11px;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    text-transform: uppercase;
    background-color: #9B1C5B;
    width: 100%;
}

.billing_details{
    background-color: #f5fafe;
    padding: 15px 20px;
    border-bottom: 1px solid #00000017;
}

.billing_details h2{
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
}

.product_detail svg{
    color: #9B1C5B;
    cursor: pointer;
}

.select_div{
    width: 100%;
    height: 40px;
    padding: 5px 0;
    padding-left: 10px;
    margin-bottom: 5px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 14px;
    color: black;
}