.suggest_hookah_place{
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    padding: 5px 8px;
    border-radius: 8px;
}

.suggest_hookah_place input{
    width: 100%;
    border: none;
    margin-right: 15px;
}

.location_icon img{
    width: 45px;
    cursor: pointer;
}

.suggest_hookah_place input:focus-visible{
    outline: none !important;
}

.suggestFormHandler{
    padding: 15px;
}

.suggestFormHandler .col-md-12, .suggestFormHandler .col-md-6 {
    display: flex;
    flex-direction: column;
}

.suggestFormHandler .col-md-12 label, .suggestFormHandler .col-md-6 label {
    text-align: left;
    font-weight: 600;
    font-size: 14px;
}

input.disabled {
    background-color: #80808030;
}