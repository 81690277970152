.Order_details{
    background-color: #f5fafe;
}

.Order_details h3{
    font-weight: 700;
    text-transform: uppercase;
}

.order_id{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 15px 20px;
    border-radius: 10px;
}


.order_id h6{
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 700;
}

.order_id h6 span{
    font-weight: 500;
    margin-left: 10px;
    color: #971b59;
}

.order_id h5{
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 700;
}

.order_id h5 span{
    font-weight: 500;
    margin-left: 10px;
    color: #971b59;
}

.order_id2{
    display: flex;
    justify-content: end;
    padding: 0px 20px;
    border-radius: 10px;
}

.order_id2 h5{
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 700;
}

.order_id2 h5 span{
    font-weight: 500;
    margin-left: 10px;
    color: #971b59;
}


.order_product_detail{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 10px;
}

.order_product_detail h6{
    background-color: #971b59;
    color: #fff;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 0;
}

.order_product_detail h4{
    text-align: center;
    margin: 20px 0 40px 0;
    font-weight: 700;
    color: #ff0000ad;
    font-size: 30px;
}

.order_product_detail .product_img img{
    width: 100px;
    height: 70px;
    border-radius: 10px;
}

.product_row{
    align-items: center;
}

.product_img{
    display: flex;
    align-items: center;
    margin-top: 20px !important;
}

.product_img p{
    padding-left: 15px;
    margin-bottom: 0;
}

.product_row p{
    margin-bottom: 0;
    font-weight: 600;
}

.product_row{
    border-bottom: 1px solid #00000030;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.order_detail_left{
    /* padding: 15px; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: #fff;
    border-radius: 10px;
}

.order_detail_right{
    /* padding: 15px; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: #fff;
    border-radius: 10px;
}

.order_detail_left h6, .order_detail_right h6{
    background-color: #971b59;
    color: #fff;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 0;
}

.order_detail_left p{
    margin-bottom: 10px !important;
    font-size: 16px;
}

.order_detail_left p span{
    font-weight: 600;
}

.Order_details p{
    margin-bottom: 0;
}


.status_msg{
    width: fit-content !important;
    margin: 30px auto !important;
    font-size: 30px !important;
    padding: 10px 25px !important;
}