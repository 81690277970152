.events_div {
    /* display: flex; */
    padding: 13px 10px;
    width: 100%;
    /* justify-content: space-around; */
}

.events_div h3 {
    margin-bottom: 0;
    font-size: 20px;
    color: #ba2271;
}

.events_div h2 {
    margin-bottom: 0;
    font-weight: 700;
    margin-top: 6px;
    font-size: 16px;
    color: #ba2271;
}

.event_details {
    border-left: 1px solid #00000036;
    margin: 0 12px;
    padding-left: 12px;
    /* width: 100%; */
}

.event_details .text {
    font-size: 13px;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.make_shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    height: 100%;
}

.make_shadow img {
    border-radius: 0 !important;
    height: 250px;
    object-fit: cover;
}

.events_div .time {
    text-align: end;
    margin-top: 10px;
}

.events_div .time h4 {
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 700;
}

.filter_map {
    border: 1px solid #3a373747 !important;
    width: 100% !important;
    border-radius: 5px !important;
}

.make_shadow h5 {
    bottom: 0 !important;
    position: relative;
    padding: 9px 20px;
    margin-top: 0;
    background-color: #ba2271;
}